import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_log_reading_modal = _resolveComponent("log-reading-modal")!
  const _component_view_log_modal = _resolveComponent("view-log-modal")!
  const _component_reading_complete_modal = _resolveComponent("reading-complete-modal")!
  const _component_bookshelf_book_modal = _resolveComponent("bookshelf-book-modal")!
  const _component_edit_book_modal = _resolveComponent("edit-book-modal")!
  const _component_help_modal = _resolveComponent("help-modal")!
  const _component_book_list = _resolveComponent("book-list")!
  const _component_carousels = _resolveComponent("carousels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#page-title-h1"
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('pages.home.pageTitle')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_log_reading_modal, {
      ref: "logReadingModal",
      "show-button": false
    }, null, 512),
    _createVNode(_component_view_log_modal, {
      ref: "viewLogModal",
      "show-button": false
    }, null, 512),
    _createVNode(_component_reading_complete_modal, { ref: "completeModal" }, null, 512),
    _createVNode(_component_bookshelf_book_modal, { ref: "bookshelfBookModal" }, null, 512),
    _createVNode(_component_edit_book_modal, { ref: "editBookModal" }, null, 512),
    _createVNode(_component_help_modal, { ref: "helpModal" }, null, 512),
    (_ctx.nowReading.length > 0)
      ? (_openBlock(), _createBlock(_component_book_list, {
          key: 1,
          books: _ctx.nowReading,
          heading: _ctx.$t('pages.home.nowReading'),
          class: "now-reading",
          ref: "nowReading"
        }, null, 8, ["books", "heading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_book_list, {
      books: _ctx.recentlyCompleted,
      heading: _ctx.$t('pages.home.recentlyCompleted'),
      "empty-text": _ctx.$t('pages.home.emptyReading'),
      class: "recently-completed"
    }, null, 8, ["books", "heading", "empty-text"]),
    _createVNode(_component_carousels),
    (_ctx.paused.length > 0)
      ? (_openBlock(), _createBlock(_component_book_list, {
          key: 2,
          books: _ctx.paused,
          heading: _ctx.$t('pages.home.pausedBooks'),
          class: "paused-books",
          ref: "pausedReading"
        }, null, 8, ["books", "heading"]))
      : _createCommentVNode("", true)
  ]))
}