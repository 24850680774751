
  import {defineComponent} from "vue";
  import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
  // import SearchBar from "@/app/components/Search/SearchBar.vue";
  import BookList from "@/app/components/BookList/BookList.vue";
  import logService from "@/app/services/LogService";
  import PersistentStorage from "@/core/store/PersistentStorage";
  import LogReadingModal from "@/app/components/modals/LogReadingModal.vue";
  import ViewLogModal from "@/app/components/modals/ViewLogModal.vue";
  import BookshelfBookModal from "@/app/components/modals/BookshelfBookModal.vue";
  import eventBus from "@/core/services/eventBus";
  import ReadingCompleteModal from "@/app/components/modals/ReadingCompleteModal.vue";
  import Carousels from '@/app/components/BookCarousel/Carousels.vue'
  import authenticationService from "@/core/services/AuthenticationService";
  import EditBookModal from "@/app/components/modals/EditBookModal.vue";
  import HelpModal from "@/app/components/modals/HelpModal.vue";
  
  export default defineComponent({
    name: "home",
    components: {ReadingCompleteModal, LogReadingModal, ViewLogModal, BookList, BookshelfBookModal, EditBookModal, HelpModal, Carousels},
    beforeUnmount() {
      eventBus.$off('logReading');
      eventBus.$off('viewLog');
      eventBus.$off('readingComplete');
      eventBus.$off('readingPaused');
      eventBus.$off('bookshelfBook');
      eventBus.$off('editBook');
      eventBus.$off('loggedReading');
      eventBus.$off('openHelp');
    },
  
    data() {
      return {
        isMounted: false
      }
    },
    computed: {
      nowReading() {
        let nowReadingBookList = (PersistentStorage.appData.log || []).filter(book => book.status == 'Reading');
        let sortedList = [...nowReadingBookList];
  
        sortedList.sort(function (a, b) {
          return new Date(b.last_read).valueOf() - new Date(a.last_read).valueOf();
        })
  
        return sortedList;
      },
      paused() {
        return (PersistentStorage.appData.log || []).filter(book => book.status == 'Paused');
      },
      recentlyCompleted() {
        return (PersistentStorage.appData.log || []).filter(book => book.status == 'Complete');
      }
    },
    mounted() {
      this.isMounted = true;
      if (authenticationService.isLoggedIn()) {
        let created = authenticationService.getSetting("profile_created");
        if (created == null) {
          authenticationService.changeSetting('profile_created', true);
          this.$router.push("/profile");
        }
      }
  
      eventBus.$on('readingComplete', (bookInfo) => {
        this.$refs.completeModal.openDialog(bookInfo);
      });
  
      eventBus.$on('readingPaused', (bookInfo) => {
        // this.$refs.pausedReading.slideTo(0);
      });
  
      eventBus.$on('loggedReading', () => {
        // this.$refs.nowReading.slideTo(0);
      });
  
      eventBus.$on('logReading', (book_instance, book) => {
        if (book_instance == null) this.$refs.logReadingModal.openDialogForNewBookInstance(book);
        else this.$refs.logReadingModal.openDialogForExistingBookInstance(book_instance);
      });
  
      eventBus.$on('viewLog', (bookInfo) => {
        this.$refs.viewLogModal.openDialog(bookInfo);
      });
  
      eventBus.$on('bookshelfBook', (book) => {
        this.$refs.bookshelfBookModal.openDialog(book);
      });
  
      eventBus.$on('editBook', (book) => {
        this.$refs.editBookModal.openDialog(book);
      });
  
      eventBus.$on('openHelp', () => {
        this.$refs.helpModal.openDialog();
      });
  
      logService.refreshReadingLog();
  
    }
  });
  